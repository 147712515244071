<template>
    <div>
        <CRow> 
            <CCol col="5">
                <CInput
                  style="width: 300px"
                  v-model="barcodeNo"
                  type="text"
                  placeholder="Barkod No Giriniz.."
                  v-on:keyup.enter="onItemCheck()"
                />
            </CCol>
            <CCol col="7">
                <b v-if="params.promoCode !=='' || params.groupName !== ''">
                    {{  'Dikkat! Promosyon Kodu (' + params.promoCode + ') ve/veya Grup Adı (' + params.groupName +') bulunuyor' }} 
                </b>
                <b v-if=" params.discountCart > 0 ">
                    {{'Sepet genelinde ' + params.discountCart + currency+ ' indirim yapıldı.' }}
                </b>
            </CCol>

        </CRow>
            
        <CRow>
            <CCol col="12" xl="12">
                <CDataTable
                    :items="detailList"
                    :fields="changeDetailFields"
                    :items-per-page="10"
                    hover
                    fixed
                    :loading="loading"
                    clickable-rows
                    pagination
                    v-on:refresh="refresh(params.id)"
                    class="modalTable"
                >
                    <template #approvedFlag="{item}">
                        <td v-if="item.orderChangeType !== 'SAME'">
                            <input
                                id="checkboxApprovedFlag"
                                style="height: 15px; width: 15px"
                                type="checkbox"
                                v-model="item.approvedFlag"
                                @change="paymentCalculator"
                            />
                        </td>
                        <td v-else></td>
                    </template>
                    <template #returnToInventoryFlag="{item}">
                        <td v-if="item.orderChangeType !== 'SAME'">
                            <input
                                id="checkboxReturnToInventoryFlag"
                                style="height: 15px; width: 15px"
                                type="checkbox"
                                v-model="item.returnToInventoryFlag"
                            />
                        </td>
                        <td v-else></td>
                    </template>
                    <template #oldProductImage="{item}">
                        <td>
                            <img v-if="item.oldSku" :src="item.oldSku.productImage.imgURL" />
                            <CBadge v-else color="danger"> YOK </CBadge>
                        </td>
                    </template>
                    <template #newProductImage="{item}">
                        <td>
                            <img v-if="item.newSku !== ''" :src="item.newSku.productImage.imgURL" />
                            <CBadge v-else color="danger"> YOK </CBadge>
                        </td>
                    </template>
                    <template #oldProductDetail="{item}">
                        <td>
                            <sup v-if="item.oldSku !== ''"> 
                                <b style="font-size: xx-small;"> 
                                    {{item.oldSku.product.name +' '+ item.oldSku.barcode +' '+ item.oldSku.code }} 
                                </b>
                            </sup >
                            <CBadge v-else color="danger"> YOK </CBadge>
                        </td>
                    </template>
                    <template #newProductDetail="{item}">
                        <td>
                            <sup v-if="item.newSku !== ''"> 
                                <b style="font-size: xx-small;"> 
                                    {{item.newSku.product.name +' '+ item.newSku.barcode +' '+ item.newSku.code }} 
                                </b>
                            </sup>
                            <CBadge v-else color="danger"> YOK </CBadge>
                        </td>
                    </template>
                    <template #salePrice="{item}">
                        <td>
                            {{ item.cartItem !== '' ? item.cartItem.salePrice : '' }}
                        </td>
                    </template>
                    <template #groupName="{item}">
                        <td>
                            <span v-if=" item.cartItem !== ''"> 
                                {{ item.cartItem.itemPromocodeRule ? item.cartItem.itemPromocodeRule.groupName : ''}}
                            </span>
                        </td>
                    </template>
                    
                    <template #changeReservedType="{item}">
                        <td>
                            <sup> 
                                <b style="font-size: xx-small;"> {{item.changeReservedType.name }} </b>
                            </sup>
                        </td>
                    </template>
                    <template #orderChangeType="{item}" style="width: 10px">
                        <td v-if="item.orderChangeType.friendlyType === 'RETURN'" 
                            style="background-color: #ffe2e2;color:#900;font-color:#900;"
                        >
                            <small>
                                <b>{{ item.orderChangeType.friendlyType }}</b>
                            </small>
                        </td>
                        <td v-else>
                            <small>
                                <b>{{ item.orderChangeType.friendlyType }}</b>
                            </small>
                        </td>
                    </template>
                    <template #changeReservedTypeNew="{item}" style="width: 10px">
                        <td>
                            <CSelect
                                placeholder="Seçiniz.."
                                horizontal
                                :options="reservedTypes"
                                :value.sync="item.orderChangeReservedType"
                            />
                        </td>
                       
                    </template>
                </CDataTable>
            </CCol>
        </CRow>
        <CRow style="margin-top: 10px">
            <CCol col="5">
                <span>
                    <b>
                        Bu siparişin iadesi (Ödeme Tipi: 
                        <span style="color:red" > {{  paymentTypes[params.paymentType - 1] }} </span>
                        ) olarak yapılacak
                    </b>
                </span>
            </CCol>
            <CCol col="5">
                <span style="color: red; margin-left: 60px">
                   <label for="customRefundFlag"> **İade Edilecek Tutar Gerçek Tutardan Farklı: </label>
                    <input
                        style="height: 15px; width: 15px"
                        v-model="params.customRefundFlag"
                        type="checkbox"
                        id="customRefundFlag"
                    />
                </span>
                <br />
                <span style="color: green; margin-left: 60px">
                    <label for="clothBagFlag"> **Bez Çanta Var: </label>
                    <input
                        style="height: 15px; width: 15px"
                        v-model="clothBagFlag"
                        type="checkbox"
                        id="clothBagFlag"
                    />
                </span>
            </CCol>
            <CCol col="2">
                <CRow>
                    <CCol>
                        <input
                            style="width: 140px"
                            v-model="refundAmount"
                            placeholder="İade Tutarı..."
                            type="text"
                        />
                    </CCol>
                    <CCol>
                        <small>
                            {{ currency}}
                        </small>
                    </CCol>

                </CRow>
            </CCol>
        </CRow>
        <CRow v-if="params.shippingCost > 0">
            <CCol>{{ '** Siparişin ' + params.shippingCost + ' ' + currency + ' kargo ücreti var.' }}</CCol>
        </CRow>
        <ConfirmModal
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="operationEvent"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            :loading="loading"
            ref="confirm"
            />
    </div>
</template>

<script>
    import moment from 'moment'
    import ConfirmModal from '../../components/confirmModal.vue'

    export default{
        name: "OrderChangeDetail",
        props:{
            params: Object,
            additional: Function
        },
        components: {
            ConfirmModal
        },
        computed:{
            detailList: function() {
                return this.$store.getters.orderChangeDetail
            },
            loading(){
                return this.$store.getters.orderLoading
            },
            reservedTypes(){
                let data = []
                this.$store.getters.orderReservedTypes.map(reserved => data.push({value: reserved.id, label: reserved.name}))
                return data
            },
            currency: function(){
                let itemReturn = this.$store.getters.orderChangeDetail.find(dl => dl.orderChangeType.friendlyType == 'RETURN')
                return itemReturn ? itemReturn.cartItem.currency.name : '';
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                modalTitle: '',
                actionType: '',
                module: 'orders',
                modalProps: {},
                modalDesc: '',
                openModal: false,
                colors: [ 'warning',  'info', 'primary',  'danger', 'secondary', 'dark', 'success'],
                paymentTypes: ['PayPalEC', 'EFT', 'CreditCard', 'FreePromo', 'DebitCard', 'IyzicoPay'],
                barcodeNo: '',
                clothBagFlag: false,
                refundAmount: 0,
                orderChangeReservedType: null,
                changeDetailFields: [
                    { key: 'id', label: 'ID', _style: 'font-size:12px' },
                    { key: 'oldProductImage', label: 'Eski_Ürün', _style: 'font-size:12px' },
                    { key: 'oldProductDetail', label: 'Detay', _style: 'font-size:12px' },
                    { key: 'newProductImage', label: 'Yeni_Ürün', _style: 'font-size:12px'},
                    { key: 'newProductDetail', label: 'Detay', _style: 'font-size:12px' },
                    { key: 'amount', label: 'Adet', _style: 'font-size:12px' },
                    { key: 'salePrice', label: 'Fiyat', _style: 'font-size:12px' },
                    { key: 'orderChangeType', label: 'Değişim_Türü', _style: 'font-size:12px'},
                    { key: 'groupName', label: 'Grup_Adı', _style: 'font-size:12px' },
                    { key: 'approvedFlag', label: 'Onayla', _style: 'font-size:12px' },
                    { key: 'returnToInventoryFlag', label: 'Stoğa_Ekle', _style: 'font-size:12px'},
                    { key: 'changeReservedType', label: 'Ayrılma_Türü', _style: 'font-size:12px'},
                    { key: 'changeReservedTypeNew', label: 'Yeni_Seç(Yanlış_Seçilmişse)', _style: 'font-size:12px'}
                ],
            }
        },
        methods: {
            async openModalEvent(actionType, module, form, data, title, desc){
                this.openModal = !this.openModal
                this.actionType = actionType
                this.module = module
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            refresh: function(id){
                this.$store.dispatch('order_changeDetail',  {changeId: id})
            },
            dateFormat: function(date){
                return moment(date).format('DD.MM.YYYY HH:mm:ss')
            },
            onItemCheck: function(){
                let toast = {}
                let foundItemIndex = this.detailList.findIndex(dl => dl.newSku !== '' &&  dl.newSku.barcode == this.barcodeNo)

                if(foundItemIndex > -1){
                    if(!this.detailList[foundItemIndex].approvedFlag){
                        this.$set(this.detailList[foundItemIndex], 'approvedFlag', true)
                    }else{
                        toast.msg = 'Girmiş olduğunuz ürün önceden onaylanmıştır!'
                    }
                }else{
                    toast.msg = 'Yeni ürünün barkod numarasını girmeniz gerekmektedir. Lütfen doğru ürün girişi yaptığınızdan emin olunuz.'
                }
                if( Object.keys(toast).length > 0){
                    toast.header = 'Uyarı'
                    toast.color = 'warning'
                    vm.$store.commit('showToast', toast)
                }
                this.paymentCalculator()
            },
            paymentCalculator: function(){
                var totalRefundAmount = 0
                this.detailList.map( dl => {
                    if(dl.orderChangeType.friendlyType == 'RETURN' && dl.approvedFlag)
                        totalRefundAmount += dl.cartItem.salePrice * dl.amount
                })
                this.refundAmount = totalRefundAmount
            },
            additionalEvent(){
                var totalRefundAmount = 0, changed = 0, totalReturn = 0, changeReservedIds = '', app = '', inv = ''
                this.detailList.map( dl => {
                    if (dl.approvedFlag) app += (app !== '' ? ',' : '') + dl.id
                    if (dl.returnToInventoryFlag) inv += (inv !== '' ? ',' : '') + dl.id
                    if (dl.orderChangeType.friendlyType === 'CHANGE' && dl.approvedFlag) changed++
                    if (dl.orderChangeType === 'RETURN' && dl.approvedFlag) totalReturn++
                    var changeReservedTypeId = dl.orderChangeReservedType
                    if (changeReservedTypeId !== undefined && changeReservedTypeId !== '')
                        changeReservedIds += (changeReservedIds !== '' ? ',' : '') + dl.id + '-' + changeReservedTypeId
                    totalRefundAmount += dl.cartItem.salePrice * dl.amount
                })
                if(this.refundAmount > (totalRefundAmount - (this.params.refundAmount !== '' ? this.params.refundAmount : 0))){
                    let toast = {
                        header:  'Uyarı!',
                        color:  'warning',
                        msg:  'Girdiğiniz iade tutarı satış fiyatından fazla, lütfen düzeltin.'
                    }
                    vm.$store.commit('showToast', toast)
                }else{
                    let data = {
                        orderChangeId: this.params.id,
                        returnToInvDetailIds: inv,
                        refundAmount: this.refundAmount,
                        approvedDetailIds: app,
                        changeReservedIds: changeReservedIds,
                        customRefundFlag: this.params.customRefundFlag ? this.params.customRefundFlag : false,
                        clothBagFlag: this.clothBagFlag ? this.clothBagFlag : false
                    }
                    this.openModalEvent('update', 'orders', 'confirm', data, 'İşlemi Onayla', 
                    '<b>DEĞİŞİM:</b> ' +changed +'<br/>' +' <b>İADE:</b> ' +totalReturn +'<br/>' +
                    '<b>İADE EDİLECEK TUTAR:</b> ' +this.refundAmount +' '+this.currency +'<br/>' +
                    '<b>STOK:</b> ' + (inv === '' ? '0' : inv.split(',').length) +'<br/>' +
                    ' <b>ONAY:</b> ' + (app === '' ? '0' : app.split(',').length)+'<br/>' 
                    +'<br/>' + 'ürün bilgileri girilmiştir. Bilgilerin doğruluğundan emin misiniz?')
                }
            },
            async operationEvent(item, action){
                if(action == 'update')
                    await this.$store.dispatch('orderChange_commit', item)
                if(this.$store.getters.orderStatus.success){
                    this.additional(item, 'close')
                }
            }
        }
    }
</script>
<style scoped>
     .table-responsive{
        height: auto;
    }
</style>